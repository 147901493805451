@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('../src/fonts/Pretendard-1.3.9/web/static/woff2/Pretendard-Regular.woff2') format('woff2'),
       url('../src/fonts/Pretendard-1.3.9/web/static/woff/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Bold';
  src: url('../src/fonts/Pretendard-1.3.9/web/static/woff2/Pretendard-Bold.woff2') format('woff2'),
       url('../src/fonts/Pretendard-1.3.9/web/static/woff/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("../src/fonts/Pretendard-1.3.9/public/static/Pretendard-SemiBold.otf");
}

@font-face {
  font-family: 'Pretendard-Light';
  src: url('../src/fonts/Pretendard-1.3.9/web/static/woff2/Pretendard-Light.woff2') format('woff2'),
    url('../src/fonts/Pretendard-1.3.9/web/static/woff/Pretendard-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../src/fonts/Pretendard-1.3.9/public/static/Pretendard-Medium.otf");
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
