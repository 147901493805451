.banner-content-clone-1 {
    --pt: 120px;
    --fsTit: 48px;
    --pbTit: 60px;
    --pdCard: 50px 70px;
    --fsCardTit: 56.5px;
    --fsCardDes: 28px;
    --fsGameType: 22px;
    --fsCardDetail: 22.5px;
    --pdImg: 0 30px;
    --widthGameCard: 1250px;
    --flexCardDir: row;
    --flexCardDirReverse: row-reverse;
    --widthImg: 45%;
    --paddingDes: 10px 0 30px;
    --paddingType: 15px 31px;

    background-color: #f3f9ff;
    padding-top: var(--pt);

    &-title {
        text-align: center;
        color: #222;
        font-family: 'Pretendard-SemiBold';
        font-size: var(--fsTit);
        padding-bottom: var(--pbTit);
        padding-left: 25px;
        padding-right: 25px;
    }

    .games-container {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding-bottom: 100px;

        .game-card {
            background: white;
            width: var(--widthGameCard);
            margin: 0 auto;
            padding: var(--pdCard);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 30px;
            border-radius: 15px;
            flex-direction: var(--flexCardDir);

            &-content {
                flex: 1
            }

            &-title {
                font-family: 'Pretendard-SemiBold';
                font-size: var(--fsCardTit);
                padding-top: 10px;
            }

            &-type {
                padding: var(--paddingType);
                font-family: 'Pretendard-SemiBold';
                font-size: var(--fsGameType);
                display: inline-flex;
                color: #fff;
            }

            &-description {
                font-size: var(--fsCardDes);
                margin-bottom: 10px;
                padding: var(--paddingDes);
                font-family: 'Pretendard-Medium';
                white-space: pre-line;
            }

            &-detail {
                display: flex;
                gap: 5px;

                p {
                    white-space: pre-line;
                    font-family: 'Pretendard-Light';
                    font-size: var(--fsCardDetail);

                }
            }

            &-img {
                padding: var(--pdImg);
                width: var(--widthImg);
                justify-content: center;
                display: flex;

                img {
                    max-width: 324px;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .game-card-reverse {
            flex-direction: var(--flexCardDirReverse);
        }
    }

    @media screen and (max-width: 1279px) {
        --widthGameCard: 90%;
        --pdImg: 0;
    }

    @media screen and (max-width: 767px) {
        --flexCardDir: column;
        --flexCardDirReverse: column;
        --widthImg: 70%;
        --fsTit: 30px;
        --pdCard: 20px 10px;
        --pt: 100px;
        --pbTit: 20px;
        --fsCardTit: 30.5px;
        --fsCardDes: 21px;
        --fsGameType: 16px;
        --fsCardDetail: 14px;
        --paddingDes: 10px 0;
        --paddingType: 8px 18px;
    }
}