@import "../../../styles/index.scss";

.box_support {
    width: 600px;
    height: 220px;
    margin: 10px 0px 0px 30px;
    padding: 47px 65px 48px 42px;
    border-radius: 24px;
    border: solid 1px #e9e9e9;
    background-color: #fff;

    @include respond-beetwen(xs) {
        width: 100%;
        margin-left: 0;
                padding: 15px 37px 0px 40px;
        height: 200px;
    }

    @include respond-beetwen(sm) {
        width: 100%;
        margin-left: 0;
           padding: 15px 37px 0px 40px;
        height: 200px;
    }

    @include respond-beetwen(md) {
        height: auto;
        margin-left: 0;
    }
}


.support_title {
    width: 250px;
    height: 30px;
    margin: 0 128px 47px 0;
    font-family: "Pretendard-SemiBold";
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #222;

    @include respond-beetwen(xs) {
        width: 100%;
       font-size: 24px;
        margin: 20px 128px 50px 0;
    }

    @include respond-beetwen(sm) {
        width: 100%;
        font-size: 24px;
        margin: 20px 128px 50px 0;
    }
}

.support_btn {
    width: 48px;
    height: 48px;
    //   margin: 20px 0px 0 0;
    padding: 0px;
    object-fit: contain;
    @include respond-beetwen(xs) {
        width: 53px;
    }

    @include respond-beetwen(sm) {
        width: 53px;
    }
}

.img_service {
    width: 180px;
    height: 110px;
    padding-top: 20px;

    @include respond-beetwen(xs) {
        // width: 50%;
        height: auto;
    }

    @include respond-beetwen(sm) {
        height: auto;
    }

    @include respond-beetwen(md) {
        height: auto;
    }
}

.support_header {
    width: 605px;
    height: 43px;
    margin: 100px 423px 0px 422px;
    font-family: "Pretendard-SemiBold";
    font-size: 48px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #222;

    @include respond-beetwen(xs) {
        font-size: 30px;
        width: 90%;
        margin-top: 50px;
        // margin-bottom: 20px;
    }

    @include respond-beetwen(sm) {
        font-size: 30px;
        width: 75%;
        margin-top: 50px;
        // margin-bottom: 40px;
    }

    @include respond-beetwen(md) {
        font-size: 48px;
        width: 75%;
        margin-top: 50px;
        margin-bottom: 30px;
    }

    @include respond-beetwen(lg) {
        font-size: 48px;
        width: 75%;
        margin-top: 60px;
        // margin-bottom: 40px;
    }
}


@include respond-beetwen(xs) {
    .support_gap {
        transform: scale(0.8);
        margin-top: -20px;
    }
}

@include respond-beetwen(sm) {
    .support_gap {
        transform: scale(0.8);
        margin-top: -20px;
    }
}


@include respond-beetwen(md) {
    .support_gap {
        transform: scale(0.8);
        margin-top: -20px;
    }
}