@font-face {
  font-family: "PretendardVariable";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Pretendard-1.3.9/public/static/Pretendard-Bold.otf");
}
@font-face {
  font-family: "Pretendard_Variable";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Pretendard-1.3.9/public/variable/PretendardVariable.ttf");
}

@font-face {
  font-family: "PretendardVariable-Regular";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Pretendard-1.3.9/public/static/Pretendard-Regular.otf");
}


@font-face {
  font-family: "Pretendard-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Pretendard-1.3.9/public/static/Pretendard-SemiBold.otf");
}
@font-face {
  font-family: "Pretendard-Light";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Pretendard-1.3.9/public/static/Pretendard-Light.otf");
}

@font-face {
  font-family: "Pretendard-ExtraBold";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Pretendard-1.3.9/public/static/Pretendard-ExtraBold.otf");
}
@font-face {
  font-family: "Pretendard-Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Pretendard-1.3.9/public/static/Pretendard-Medium.otf");
}

  
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');
  
  
