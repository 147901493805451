@import "../../../styles/index.scss";

.box {
    background-color: #ffff;
    border-radius: 20px;
    border: 1px solid #e9e9e9;
    height: 330px;
    width: 396px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    gap: 20px;

    @include respond-beetwen(xs) {
        width: 100%;
        height: 170px;
    }

    @include respond-beetwen(sm) {
        width: 100%;
        height: 190px;
    }

    @include respond-beetwen(md) {
        width: 100%;
        height: 300px;
        gap: 30px;
    }

    @include respond-beetwen(lg) {
        width: 100%;
        height: auto;
    }

    .service_description {
        width: 200px;
        height: 18px;
        margin: 0px 2px 0 0;
        font-family: "Pretendard-Light";
        font-size: 20px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #222;

        @include respond-beetwen(xs) {
            font-size: 13px;
            width: 100%;
            $max-width: 100%;

            @if ($max-width < 100%) {
                margin-top: 10%;
            }
        }

        @include respond-beetwen(sm) {
            font-size: 13px;
            width: 100%;
        }

        @include respond-beetwen(md) {
            font-size: 20px;
            width: 100%;
        }

        @include respond-beetwen(lg) {
            font-size: 20px;
            width: 100%;
        }
    }


    .service_sub {
        width: 390px;
        height: 30px;
        margin: 0 0 21px;
        font-family: "Pretendard-SemiBold";
        font-size: 32px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #222;

        @include respond-beetwen(xs) {
            font-size: 18px;
            margin-bottom: 2%;
            width: 100%;
        }

        @include respond-beetwen(sm) {
            font-size: 18px;
            margin-bottom: 5%;
            width: 100%;
        }

        @include respond-beetwen(md) {
            font-size: 32px;
            margin-bottom: 5%;
            width: 100%;
        }

        @include respond-beetwen(lg) {
            font-size: 32px;
            margin-bottom: 5%;
            width: 100%;
        }
    }

    .img_contain {
        width: 124px;
        height: 117px;
        margin-top: 53px;
        margin-left: 240px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @include respond-beetwen(xs) {
            width: 80px;
            height: 50px;
            margin-left: 55%;
            margin-top: 30px;
        }

        @include respond-beetwen(sm) {
            width: 100px;
            height: 140px;
            margin-left: 45%;
            margin-top: 30px;

            img {
                width: 100%;
                height: 50%;
                object-fit: contain;
            }
        }

        @include respond-beetwen(md) {
            width: 200px;
            height: 174px;
            margin-left: 40%;
            margin-top: 90px;

            img {
                width: 100%;
                height: 50%;
                object-fit: contain;
            }
        }

        @include respond-beetwen(lg) {
            width: 200px;
            height: 174px;
            margin-left: 50%;
            margin-top: 90px;

            img {
                width: 100%;
                height: 80%;
                object-fit: contain;
            }
        }
    }

}

.service_title {
    width: 755px;
    height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    //   margin: 100px 100px 40px;
    font-family: "Pretendard-SemiBold";
    font-size: 48px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #222;
    position: relative;
    z-index: 2;

    @include respond-beetwen(xs) {
        font-size: 26px;
        width: 75%;
               margin-top: 60px;
        margin-bottom: 10px;
    }

    @include respond-beetwen(sm) {
        font-size: 36px;
        width: 80%;
              margin-top: 80px;
        margin-bottom: 20px;
    }

    @include respond-beetwen(md) {
        font-size: 48px;
        width: 70%;
        margin-top: 80px;
        margin-bottom: 20px;
    }

    @include respond-beetwen(lg) {
        font-size: 48px;
        width: 100%;
        margin-top: 100px;
        margin-bottom: 70px;
    }
}


.res_service {
    @include respond-beetwen(xs) {
        transform: scale(0.9);
    }

    @include respond-beetwen(sm) {
        transform: scale(0.9);
    }

    @include respond-beetwen(md) {
        transform: scale(0.9);
    }

    @include respond-beetwen(lg) {
        padding: 10px;
    }
}