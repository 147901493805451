@import "../../styles/index.scss";

.navbar {
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
  font-family: 'Pretendard-Regular';
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #222;
}

@keyframes slideIn {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate_slideIn {
  animation: slideIn 0.5s ease-in-out;
}


@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .navbar {
    padding-left: 20px;
    padding-right: 50px;
    cursor: pointer;
    font-family: 'Pretendard-Regular';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #222;
  }

  .nav {
    margin-right: 120px;
  }

  .logo {
    margin-left: 130px;

  }
}

@media screen and (min-width: 1280px) and (max-width: 1399px) {
  .logo {
    margin-left: 30px;

  }
}

//logo-img
.logo-img {
  width: 211px;
  height: 87px;
  margin: 0 680px 0 0;
  object-fit: contain;
  // background-color: #222;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include respond-beetwen(xs) {
    width: 80px;
    margin: 0 0px 0 0;
    height: 38px;
    margin-left: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 38px;
    }
  }

  @include respond-beetwen(sm) {
    width: 80px;
    margin: 0 0px 0 0;
    height: 38px;
    margin-left: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 38px;
    }
  }

  @include respond-beetwen(md) {
    width: 130px;
    margin: 0 0px 0 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 90px;
    }
  }

  @include respond-beetwen(lg) {
    width: 130px;
    margin: 0 0px 0 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 90px;
    }
  }
}

//menu-icon
.menu-icon {
  width: 68px;
  height: 46px;
  margin: 30px 0 11px 680px;
  object-fit: contain;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include respond-beetwen(xs) {
    width: 40px;
    height: auto;
    margin: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 20px;
    }
  }

  @include respond-beetwen(sm) {
    width: 40px;
    height: auto;
    margin: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 20px;
    }
  }

  @include respond-beetwen(md) {
    width: 40px;
    height: auto;
    margin: 20px 15px 11px 0px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 45px;
    }
  }

  @include respond-beetwen(lg) {
    width: 40px;
    height: auto;
    margin: 20px 15px 11px 0px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 45px;
    }
  }
}


//navbar_header
.navbar_header {
  @include respond-beetwen(lg) {
    display: none;
  }

  @include respond-beetwen(xl) {
    display: none;
  }
}

//nav_bar_1

.nav_bar_1 {
  @include respond-beetwen(xs) {
    display: none;
  }

  @include respond-beetwen(sm) {
    display: none;
  }

  @include respond-beetwen(md) {
    display: none;
  }

  @include respond-beetwen(lg) {
    display: block;
  }

  @include respond-beetwen(xl) {
    display: block;
  }
}


//
.sticky-position {
  position: relative;
  padding: 1rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }

  &.has-background {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding-top: 10px;
  z-index: 9999;
  margin-top: 0px;
  height: 80px;
  width: 100%;
  padding-top: 0px;
  opacity: 0.9;

  .navbar-item-text {
    color: #0c257b;
    font-family: 'Pretendard-Medium';

    &:hover {
      color: #1188ed;
    }

  }

  .navbar-item-text-active {
    color: #1188ed;
  }

}

.navbar_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  align-items: center;
  opacity: 0.9;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.menu-sticky {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

// .menu.sticky {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   background-color: #fff;
//   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
//   padding-top: 10px;
//   z-index: 9999;
//   margin-top: 0px;
//   height: 80px;
//   width: 100%;

//   opacity: 0.9;
// }

.logo {

  img {
    width: 116px;
    height: 48px;
    margin: 0 110px 20px 6px;
    // width: 100%;
    // height: 100%;
    // margin-top: -20px;
    object-fit: contain;
  }
}

@include respond-beetwen(xs) {
  .menu {
    display: none;
  }
}

@include respond-beetwen(sm) {
  .menu {
    display: none;
  }
}

@include respond-beetwen(md) {
  .menu {
    display: none;
  }
}

@include respond-beetwen(lg) {
  .menu {
    display: block;
  }
}

.aside {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  z-index: 1;
  transition: all 0.3s;
  transform: translateX(0%);
  height: 100vh;
  z-index: 9999;

  &-container {
    display: flex;
    height: 100%;
    width: 100%;

    .aside-content {
      width: 60%;
      background-color: #0c257b;
      height: 100%;
      // z-index: 999999;
      padding: 30px;

      .logo-mobile {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 60%;
          height: 100%;
          object-fit: contain;
        }
      }

      &-menu {
        padding-top: 50px;
      }

      &-item {
        width: 100%;
        padding: 16px 0;

        span {
          font-size: 20px;
          font-family: 'Pretendard-Medium';
        }
      }
    }

    .backdrop {
      flex: 1;
      background-color: #666;
      opacity: 0.6;
    }
  }
}

.aside-hidden {
  transform: translateX(100%);
}


.ant-drawer-body {
  width: 100%;
  background-color: #0c257b;
  height: 100%;
  // z-index: 999999;
  padding: 30px;

  .logo-mobile {
    display: flex;
    align-items: start;
    justify-content: start;

    img {
      width: 60%;
      height: 100%;
      object-fit: contain;
    }
  }

  .aside-content {
    &-menu {
      padding-top: 50px;
    }

    &-item {
      width: 100%;
      padding: 16px 0;

      span {
        color:#fff;
        font-size: 20px;
        font-family: 'Pretendard-Medium';
      }
    }
  }

}