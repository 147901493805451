@import"../../../styles/index.scss";

.bg {
  // flex-direction: column;
  margin: 0;
  padding: 0;
  border-radius: 32px;
  background-color: #fff;
  display: flex;
  align-items: center;

}

.text_box_01 {
  width: 105px;
  height: 36px;
  margin: 0 603px 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CashGames {
  font-family: "Pretendard-Medium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.43;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.tour {
  font-family: "Pretendard-Medium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.43;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.banner_title {
  width: 134px;
  height: 36px;
  margin: 20px 574px 31px 0;
  font-family: "Pretendard-SemiBold";
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}

.buy_in {
  margin: 0px 294px 0px 8px;
  width: 428px;
  font-family: "Pretendard-Light";
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}

.banner_img {
  width: 378px;
  height: 315px;
  margin: 3px 0 0 0px;
  object-fit: contain;
}

.tour_des {
  width: 206px;
  height: 45px;
  margin: 0px 238px 53px 237px;
  font-family: "Pretendard-Light";
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}

.tour_sub {
  width: 428px;
  padding-left: 5px;
  font-family: "Pretendard-Light";
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}

._dot_content {
  margin-top: -30px;
}

.banner_description {
  width: 220px;
  height: 45px;
  margin: 15px 513px 54px 0;
  font-family: "Pretendard-Light";
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}

.title_tour {
  width: 123px;
  height: 33px;
  margin: 20px 321px 0px 237px;
  font-family: "Pretendard-SemiBold";
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}











@include respond-beetwen(xs) {

  .bg {
    width: 100%;
    margin-bottom: 30px;
  }

  ._dot_content_res {
    margin-top: -45px;
  }

  .banner_img {
    width: 250px;
    height: 215px;
    margin: 10px 25px 25px 25px;
    object-fit: contain;
  }

  .title_tour {
    width: 150px;
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .tour_des {
    width: 170px;
    font-size: 14px;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .tour_sub {
    font-size: 14px;
    width: 250px;
    margin-left: 10px;
    margin-right: 20px;
  }

  .banner_description {
    margin-bottom: 0;
  }

  .sub_box {
    padding-left: 15px;
  }

  .dot {
    margin-top: -20px;
  }

  .buy_in {
    font-size: 14px;
    width: 250px;
    margin-left: 10px;
    margin-right: 20px;
  }

  .banner_description {
    width: 160px;
    font-size: 14px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }

  .banner_title {
    width: 150px;
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .text_box {
    width: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px;
  }


  .text_box_02 {
    width: 100px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }

  .banner_1 {
    width: 100%;
  }

  .text_box_01 {
    width: 100px;
    height: 30px;
    margin-bottom: 5px;
  }

  .banner_img_res_2 {
    display: block;
  }

  .banner_img_res_1 {
    display: none;
  }

  .banner_bottom {
    margin-bottom: 20px !important;
  }

  .banner_1 {

    margin-bottom: 30px;
  }


  .banner_img2 {
    display: none;
  }


  .banner_img {
    margin: -175px 25px 20px 0px;
    position: relative;
    width: 100%;
    height: 148px;
    overflow: hidden;
    border-radius: 30px;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
           opacity: 0.2;
    }

    .overlay {
      position: absolute;
      top: 40%;
      left: 100%;
      transform: translate(-50%, -50%);
      width: 85%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
    }
  }

  .banner_img3 {
    position: relative;
    /* width: 100%; */
    height: 148px;
    overflow: hidden;
    border-radius: 30px;
    margin: -160px 25px 40px 0px;
    object-fit: contain;


    img {
      width: auto;
      height: 100%;
      object-fit: contain;
            opacity: 0.2;
    }

    .overlay {
      position: absolute;
        top: 28%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
    }
  }

  .tour {
    text-align: end;
  }

  .text_box_02 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    // margin-left: 270px;
  }

  .title_tour {
    display: flex;
    width: 100%;
    justify-content: end;
    text-align: end;
    margin-left: -20px;
  }

  .button_tour {
    display: flex;
    justify-content: end;
    // margin-left:  -30px;
    margin-right: 20px;
  }

  .tour_content {
    display: flex;
    justify-content: end;
    padding-right: 20px;
  }

  .tour_des {
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: end;
    margin-left: -85px;
  }


  .tour_des {
    text-align: end;
    margin-left: 80px;
  }

  .buy_in {
    width: 100%;
  }

  .tour_sub {
    font-size: 14px;
    width: 100%;
    margin-left: 0px;
    // margin-right: -35px;
  }
}


@include respond-beetwen(sm) {
  .bg {
    width: 100%;
    margin-bottom: 30px;
  }

 .banner_1 {
    width: 100%;
    padding-bottom: 10px;
  }

  .text_box_01 {
    width: 100px;
    height: 30px;
    margin-bottom: 5px;
  }

  .text_box_02 {
    width: 100px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }

  .text_box {
    width: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px;
  }

  .banner_title {
    width: 150px;
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .banner_description {
    width: 160px;
    font-size: 14px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }

  .buy_in {
    font-size: 14px;
    width: 428px;
    margin-left: 10px;
    margin-right: -55px;
  }

  .sub_box {
    padding-left: 25px;
  }

  .dot {
    margin-top: 0px;
  }

  .banner_img {
    width: 250px;
    height: 215px;
    margin: 10px 25px 25px 55px;
    object-fit: contain;
  }

  .title_tour {
    width: 150px;
    font-size: 30px;
    margin-top: -10px;
    margin-bottom: 10px;
    margin-left: 0;
  }

  .tour_des {
    width: 170px;
    font-size: 14px;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .tour_sub {
    font-size: 14px;
    width: 300px;
    margin-left: 10px;
    margin-right: -35px;
  }

  .banner_description {
    margin-bottom: 0;
  }

  .banner_img_res_2 {

    display: block;
  }

  .banner_img_res_1 {

    display: none;
  }

  .banner_img {
    margin: -185px 25px 0px 0px;
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 30px;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
      opacity: 0.2;
    }

    .overlay {
      position: absolute;
      top: 40%;
      left: 100%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
    }
  }

  .banner_img3 {
    position: relative;
    /* width: 100%; */
    height: 200px;
    overflow: hidden;
    border-radius: 30px;
    margin: -145px 25px 0px 0px;
    object-fit: contain;


    img {
      width: auto;
      height: 100%;
      object-fit: contain;
           opacity: 0.2;
    }

    .overlay {
      position: absolute;
      top: 28%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
    }
  }

  .banner_bottom {
    margin-bottom: 20px !important;
  }

  .banner_1 {
    width: 100%;
    margin-bottom: 30px;
  }

  .banner_title {
    font-size: 35px;
  }

  .banner_img2 {
    display: none;
  }



  .tour {
    text-align: end;
  }

  .text_box_02 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    // margin-left: 270px;
  }

  .title_tour {
    display: flex;
    width: 100%;
    justify-content: end;
    text-align: end;
    margin-left: -30px;
  }

  .button_tour {
    display: flex;
    justify-content: end;
    // margin-left:  -30px;
    margin-right: 30px;
  }

  .tour_content {
    display: flex;
    justify-content: end;
    padding-right: 30px;
  }

  .tour_des {
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: end;
    margin-left: -85px;
  }

  .text_box_1 {
    width: 100%;
  }

  .buy_in {
    width: 100%;
    margin-left: 0;
    margin-right: 40px;
  }

  .tour_sub {
    width: 100%;
    margin-right: 30px;
  }
}


@include respond-beetwen(md) {
  .title_tour {
    width: 150px;
    font-size: 30px;
    margin-top: -10px;
    margin-left: 0;
    margin-bottom: 5px;

  }

  .text_box_01 {
    margin: 0 603px 8px 0;
  }

  .tour_des {
    width: 220px;
    font-size: 16px;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .sub_box {
    padding-left: 30px;
  }

  .banner_title {
    width: 150px;
    font-size: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .banner_description {
    width: 220px;
    font-size: 18px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }

  .buy_in {
    font-size: 18px;
    width: 700px;
    margin-left: 10px;
    margin-right: -360px;
  }

  .bg {
    width: 100%;
    margin-bottom: 30px;
  }

  .text_box {
    width: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px;
  }

  .text_box_02 {
    width: 120px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }


  .banner_img_res_2 {

    display: block;
  }

  .banner_img_res_1 {

    display: none;
  }



  .banner_bottom {
    margin-bottom: 20px !important;
  }

  .banner_1 {
    width: 100%;
    margin-bottom: 30px;
  }

  .banner_title {
    font-size: 40px;
  }

  .banner_img2 {
    display: none;
  }


  .banner_img {
    margin: -255px 25px 0px 9px;
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
    border-radius: 30px;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
            opacity: 0.2;
    }

    .overlay {
      position: absolute;
      top: 40%;
      left: 100%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
    }
  }

  .banner_img3 {
    position: relative;
    /* width: 100%; */
    height: 250px;
    overflow: hidden;
    border-radius: 30px;
    margin: -170px 25px 0px 0px;
    object-fit: contain;


    img {
      width: auto;
      height: 100%;
      object-fit: contain;
           opacity: 0.2;
    }

    .overlay {
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
    }
  }

  .tour {
    text-align: end;
  }

  .banner_description {
    font-size: 18px;
    width: 220px;
    height: 45px;
    margin: 15px 513px 54px 0;
  }

  .text_box_02 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    // margin-left: 270px;
  }

  .title_tour {
    display: flex;
    width: 100%;
    justify-content: end;
    text-align: end;
    margin-left: -30px;
    font-size: 40px;
  }

  .button_tour {
    display: flex;
    justify-content: end;
    // margin-left:  -30px;
    margin-right: 30px;
  }

  .tour_content {
    display: flex;
    justify-content: end;
    padding-right: 30px;
  }

  .tour_des {
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: end;
    margin-left: 0px;
    font-size: 18px;
    width: 300px;
    margin-top: 20px;
  }

  .text_box_1 {
    width: 100%;
  }

  .buy_in {
    font-size: 18px;
   width: 95%;
  }

  .tour_sub {
    font-size: 18px;
   width: 95%;
  }

  ._dot_content {
    margin-top: -30px;
  }
}

@include respond-beetwen(lg) {
    .button_tour {
    display: flex;
    justify-content: start;
    // margin-left:  -30px;
    // margin-right: 20px;
  }
  .banner_img_res_2 {

    display: none;
  }

  .banner_img_res_1 {

    display: block;
  }

  .banner_img2 {
    display: block;
  }

  .banner_img3 {
    display: none;
  }

  .bg {
    width: 100%;
    height: auto;
    align-items: flex-start;
    margin-bottom: 30px;
    // flex-direction: column;
  }

  .banner_1 {
    width: 100%;
    padding-bottom: 30px;
  }

  .banner_content_1 {
    padding-left: 53px !important;
    padding-top: 50px;
    padding-bottom: 53px;
  }

  .text_box_01 {
    width: 100px;
    height: 40px;
    margin-bottom: 15px;
  }

  .text_box_02 {
    width: 100px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }

  .text_box {
    width: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px;
  }

  .responsive_content {
    padding-left: 30px !important;
  }

  .banner_title {
    width: 150px;
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .banner_img2 {
    margin: 20px 25px 25px 20px !important;
  }

  .banner_description {
    width: 220px;
    font-size: 16px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }

  .tour_content {
    margin-bottom: 30px;
    justify-content: start;
  }

  .buy_in {
    font-size: 16px;
    width: 380px;
    margin-left: 10px;
    margin-right: -250px;
  }

  .sub_box {
    padding-left: 30px;
  }

  .dot {
    margin-top: 0px;
  }

  .banner_2 {
    flex-direction: row;
  }

  .banner_img {
    width: 320px;
    height: 300px;
    margin: 20px 25px 25px 290px;
    object-fit: contain;
  }

  .title_tour {
    width: 150px;
    display: block;
    font-size: 30px;
    margin-top: 0px;
    margin-left: 0;
    margin-bottom: 30px;
    text-align: start;
  }

  .tour_des {
    width: 220px;
    font-size: 16px;
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: start;
  }

  .tour_sub {
    font-size: 16px;
    width: 430px;
    margin-left: 10px;
    margin-right: -80px;
  }

  .banner_description {
    margin-bottom: 0;
  }

  ._dot_content {
    margin-top: -30px;
  }

  .dot_responsive {
    margin-top: -25px;
  }
}


@include respond-beetwen(xl) {
  .banner_img_res_2 {
    display: none;
  }

  .banner_img_res_1 {
    display: block;
  }

  .banner_img2 {
    display: block;
  }

  .banner_img3 {
    display: none;
  }

  .bg {
    width: 1250px;
    height: 480px;
    margin: 30px 0 32px;
    padding: 80px 92px 82px 72px;
    border-radius: 32px;
    background-color: #fff;
    display: flex;
    align-items: center;
  }

  .text_box_01 {
    width: 105px;
    height: 36px;
    margin: 0 603px 20px 0;
    // padding: 13px 14px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._dot {
    width: 8px;
    height: 8px;
    margin: 60px 8px 23px 1px;
    object-fit: contain;
    background-color: #ccc;
  }

  .text_box_02 {
    width: 112px;
    height: 36px;
    margin: 0 330px 10px 239px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}


.container_banner_scale{
  @include respond-beetwen(xl) {
    transform: none;
  }
  @include respond-beetwen(lg) {
    transform: scale(0.9);
    width: 100%;
  }
  @include respond-beetwen(md) {
     transform: scale(0.9);
     width: 100%;
     margin-top: -40px;
  }
  @include respond-beetwen(sm) {
    transform: scale(0.9);
    width: 100%;
         margin-top: -20px;
  }
  @include respond-beetwen(xs) {
     transform: scale(0.9);
     width: 100%;
          margin-top: -20px;
  }
 
}