@import "../../../styles/index.scss";

.container {
  background: url("../../../assets/01_main_bg_2024-06-28/01_main_bg.png") no-repeat center;

  @include respond-beetwen(xs) {
    width: 100%;
    height: 450px !important;
    background: none;
    margin-bottom: 30px;

    .background_image {
      margin: 240px 70px 10px 0px;
      position: relative;
      width: 100%;
      height: 480px;
      overflow: hidden;
      z-index: -1;

      img {
        width: auto;
        height: 100%;
        object-fit: cover;
      }

      .overlay_1 {
        position: absolute;
        top: 41%;
        left: 92%;
        transform: translate(-94%, -30%);
        width: 135%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
      }
    }

    .content_div_3 {
      position: absolute;
      z-index: 1;
      top: 0;
    }


    .content_div_2 {
      margin-top: 100px;
      transform: scale(0.9);
      @media screen and (max-width: 767px) {
          margin-top: 100px;
          padding-left: 40px !important;
        }
    }
  }

  @include respond-beetwen(sm) {
    width: 100%;
    height: 430px !important;
    background: none;
    margin-bottom: 50px;

    .background_image {
       margin: 240px 70px 10px 0px;
      position: relative;
      width: 100%;
      height: 480px;
      overflow: hidden;
      z-index: -1;

      img {
        width: auto;
        height: 100%;
        object-fit: cover;
      }

      .overlay_1 {
        position: absolute;
        top: 45%;
        left: 95%;
        transform: translate(-94%, -30%);
        width: 135%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
      }
    }

    .content_div_3 {
      position: absolute;
      z-index: 1;
      top: 0;
    }


    .content_div_2 {
      margin-top: 150px;
      transform: scale(0.9);
      @media screen and (max-width: 767px) {
        margin-top: 100px;
        padding-left: 40px !important;
      }
    }
  }

  @include respond-beetwen(md) {
    width: 100%;
    height: 1050px !important;
    background: none;
    display: block;
    margin: 0;
    overflow: visible;
    margin-bottom: 30px;

    .background_image {
      width: 100%;
      height: 1050px;
      position: relative;
      overflow: hidden;
      .overlay_1{
        display: none;
        
      }   
      img {
        width: 100%;
        height: 50%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 51%;
        transform: translate(-49%, 0%);
      }
    }

    .content_div_3 {
      position: absolute;
      z-index: 1;
      top: 0;
    }

    .content_div_2 {
      margin-top: 200px;
      transform: scale(0.9);
      // padding-top: 530px
    }
  }

  @include respond-beetwen(lg) {
    background: url("../../../assets/01_main_bg_2024-06-28/01_main_bg.png") no-repeat center;
    background-size: contain;
    width: 100%;
    height: 600px;
    // height: 710px;
    // bottom: 300px;
    // position: relative;
    // margin-top: -230px;

    padding: 15px 0px 50px 150px;

    .overlay_1 {
      display: none;
    }
    .background_image {
      display: none;
    }

    .content_div_3 {
      display: block;
    }

    .content_div_2 {
      margin-top: 200px;
      padding-top: 0px;
    }
  }

  @include respond-beetwen(xl) {
    background-size: contain;
    background: none;
    width: 100%;
    margin-top: -20px;
    // margin-left: 60px;
    height: 700px;

    // padding: 0px 0px 47px 160px;
    .background_image {
      width: 100%;
      height: 650px;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 5%;
        left: 50%;
        transform: translate(-45%, 0%);
      }
    }

    .content_div_3 {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 50%;
    }


    .content_div_3 {
      display: block;
    }
  }

  // @media screen and (min-width: 1536px) {
  //   padding: 15px 0px 47px 335px;
  //   margin-left: 0px;
  //   margin-top: 0;
  // }

}

.img_icon_dow {
  @include respond-beetwen(xs) {
    width: 80px;
    height: 80px;
    margin-bottom: 50px;
  }

  @include respond-beetwen(sm) {
    width: 100px;
    height: 100px;
    margin-bottom: 50px;
  }

  @include respond-beetwen(md) {
    width: 120px;
    height: 120px;
    margin-bottom: 50px;
  }

  @include respond-beetwen(lg) {
    width: 20px;
    height: 50px;
    // margin-bottom: 50px;
  }

  @include respond-beetwen(xl) {
    margin-bottom: 50px;
    width: 26px;
    height: 48px;
  }
}

.background {
  width: 1920px;
  height: 800px;
  padding: 0 0 64px;
  object-fit: contain;
}

.title {
  width: 512px;
  height: 70px;
  font-family: "Pretendard-SemiBold";
  font-size: 50px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: start;
  color: #222;

  @include respond-beetwen(xs) {
    font-size: 40px;
    margin-top: 15px;
    width: 220px;
    height: 30px;
    text-align: start;

  }

  @include respond-beetwen(sm) {
    font-size: 37px;
    margin-top: 15px;
    width: 205px;
    height: 30px;
    text-align: start;
  }

  @include respond-beetwen(md) {
    font-size: 80px;
    margin-top: 0px;
    width: 450px;
    height: 100px;
    text-align: start;
  }

  @include respond-beetwen(lg) {
    width: 512px;
    height: 40px;
    font-family: "Pretendard-SemiBold";
    font-size: 30px;
  }
}

.content_div_1 {
  @include respond-beetwen(xs) {
    margin-top: 90px;
  }

  @include respond-beetwen(sm) {
    margin-top: 90px;
  }

  @include respond-beetwen(md) {
    margin-top: 60px;
  }

  @include respond-beetwen(lg) {
    margin-top: 10px;
  }
}

.title1 {
  width: 540px;
  height: 56px;
  padding-top: 20px;
  padding-bottom: 80px;
  //   margin: 36px 697px 158px 698px;
  font-family: "Pretendard-Light";
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: start;
  color: #222;

  @include respond-beetwen(xs) {
    font-size: 24px;
    height: 0;
    padding-top: 0px;
    padding-bottom: 35px;
    width: 275px;
    text-align: start;
  }

  @include respond-beetwen(sm) {
    font-size: 24px;
    height: 0;
    padding-top: 0px;
    padding-bottom: 35px;
    width: 275px;
    text-align: start;
  }

  @include respond-beetwen(md) {
    font-size: 40px;
    height: 0;
    padding-top: 50px;
    padding-bottom: 0px;
    width: 550px;
    text-align: start;
  }

  @include respond-beetwen(lg) {

    font-size: 18px;
    height: 56px;
    padding-top: 20px;
    padding-bottom: 80px;
    width: 325px;
  }
}

.title2 {
  width: 100px;
  height: 17px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  font-family: "Pretendard-SemiBold";
  font-size: 18.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.65;
  letter-spacing: normal;
  text-align: center;
  color: #000;

  @include respond-beetwen(xs) {
    font-size: 20.5px;
    margin-top: 80px;
  }

  @include respond-beetwen(sm) {
    font-size: 30.5px;
    width: 150px;
    margin-bottom: 50px;
    margin-top: 120px;
  }

  @include respond-beetwen(md) {
    font-size: 40.5px;
    width: 200px;
    margin-bottom: 80px;
    margin-top: 350px;
  }

  @include respond-beetwen(lg) {
    font-size: 17.5px;
    width: 200px;
    margin-bottom: 30px;
    margin-top: 0px;
  }

}



.banner_header {
  font-family: "Pretendard-SemiBold";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 682px;
  height: 44px;
  margin: 100px 284px 40px;
  font-size: 47px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #222;
  z-index: 10;
  position: relative;

  @include respond-beetwen(xs) {
    width: 271px;
    height: 20px;
    font-size: 26px;
    margin-top: 90px;
    margin-bottom: 30px;
  }

  @include respond-beetwen(sm) {
    width: 300px;
    height: 30px;
    font-size: 30px;
      margin-top: 90px;
    margin-bottom: 30px;
  }

  @include respond-beetwen(md) {
    width: 482px;
    height: 44px;
    font-size: 47px;
    margin: 70px 284px 40px;
    // margin-bottom: 100px;
  }
}

.banner_1 {
  background-color: #fcd900;
}

.banner_2 {
  background-color: #3ec0f7;
}

.banner_3 {
  background-color: #d1cdcd;
}


@include respond-beetwen(xs) {
  .banner_container_res_1 {
    display: block;
  }

  .banner_container_res_2 {
    display: none;
  }
}

@include respond-beetwen(sm) {
  .banner_container_res_1 {
    display: block;
  }

  .banner_container_res_2 {
    display: none;
  }
}

@include respond-beetwen(md) {
  .banner_container_res_1 {
    display: block;
  }

  .banner_container_res_2 {
    display: none;
  }
}

@include respond-beetwen(lg) {
  .banner_container_res_1 {
    display: none;
  }

  .banner_container_res_2 {
    display: block;
  }
}

@include respond-beetwen(xl) {
  .banner_container_res_1 {
    display: none;
  }

  .banner_container_res_2 {
    display: block;
  }
}


@include respond-beetwen(xl) {
  .content_div_4_title {
    margin-left: 70%;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1399px) {
  .content_div_4_title {
              margin-left: -5%;
        margin-top: 70px;
      width: 100% !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .content_div_4_title {
        margin-left: -8%;
        margin-top: 40px;
  }
}
