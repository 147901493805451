@mixin respond-beetwen($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: $mobile) {
      @content;
    }
  } @else if $breakpoint == sm {
    @media (min-width: $mobile) and (max-width: $tablet-x) {
      @content;
    }
  } @else if $breakpoint == md {
    @media (min-width: $tablet-s) and (max-width: $large-desktop) {
      @content;
    }
  } @else if $breakpoint == lg {
    @media (min-width: $large) and (max-width: $desktop-s) {
      @content;
    }
  } @else if $breakpoint == xl {
    @media (min-width: $desktop) {
      @content;
    }
  }@else if $breakpoint == lgs {
    @media (min-width: $desktop-s) {
      @content;
    }
  }
}
