@import "../../styles/index.scss";

.footer_content {
  width: 634px;
  //   height: 71px;
  margin: 0px 0px;
  font-family: "Pretendard-Light";
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  //   line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #222;


}

@include respond-beetwen(xs) {
  .footer_container {
    transform: scale(0.8);
    margin-bottom: 10px;
    padding-top: 5px;
  }
  .footer_content {
    width: 100%;
    // font-size: 13px;
  }
}

@include respond-beetwen(sm) {
  .footer_container {
    transform: scale(0.8);
    margin-bottom: 0px;
    padding-top: 5px;
  }
  .footer_content {
    width: 100%;
    // font-size: 16px;
  }
}

@include respond-beetwen(md) {
  .footer_container {
    transform: scale(0.9);
    margin-bottom: 20px;
    padding-top: 5px;
  }
  .footer_content {
    width: 100%;
    // font-size: 18px;
  }
}

@include respond-beetwen(lg) {

  .footer_content {
    width: 100%;
    // font-size: 18px;
  }
}

@include respond-beetwen(xl) {

  .footer_content {
    width: 100%;
    // font-size: 18px;
  }
}