$primary-color: #000;
$secondary-color: #fff;
$highlight-color: 468px;
$font-family: 'Arial, sans-serif';

body {
    font-family: $font-family;
    margin: 0;
    padding: 0;
    color: $primary-color;
    background-color: $secondary-color;
}

main {
    .hero {
        text-align: center;
        padding: 20px;

        .content {
            margin-bottom: 20px;

            h1 {
                font-size: 24px;
                margin-bottom: 10px;
                font-weight: bold;
            }

            p {
                font-size: 16px;
                margin: 5px 0;
            }
        }

        .illustration {

            margin: -175px 70px 10px -19px;
            position: relative;
            width: 114%;
            height: 430px;
            overflow: hidden;
            z-index: -1;
            img {
                width: auto;
                height: 100%;
                object-fit: cover;
                // opacity: 0.5;
            }

            .overlay {
                position: absolute;
                top: 45%;
                left: 100%;
                transform: translate(-94%, -30%);
                width: 175%;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                overflow: hidden;
            }


        }

        .cta {
            font-weight: bold;
            margin-top: 20px;
        }
    }
}